<template>
    <v-container fluid>
        <!-- SCREEN TITLE -->
        <v-row align="center" class="mt-16 mb-n2 noSpace" justify="center">
            <p class="centuryGothicBold screenTitle">CONTACTO</p>
        </v-row>

        <!-- OFFICES LOCATION -->
        <v-row class="mb-6 noSpace" align="center" justify="center">
            <!-- LOCATION ON MAP COLUMN -->
            <v-col cols="12" md="7" class="noSpace">
                <v-container fluid class="locationMap">
                    <gmaps-map :options="location.mapOptions">
                        <!-- LOCATION ON MAP MARKER -->
                        <gmaps-marker :options="location.markerOptions"></gmaps-marker>
                    </gmaps-map>
                </v-container>
            </v-col>

            <!-- LOCATION DETAILS COLUMN -->
            <v-col cols="12" md="5" align-self="start" class="noSpace">
                <!-- LOCATION NAME -->
                <v-row class="locationDetail locationName noSpace" align="start" justify="start">
                    <p class="centuryGothicBold screenTitle">{{ customerService.title }}</p>
                </v-row>

                <!-- LOCATION PHONE -->
                <v-row class="noSpace locationDetail" align="start" justify="start">
                    <a :href="`tel:${customerService.phone}`"  target="_blank">
                        <h2 class="centuryGothicBold locationDataText mt-n4">{{ customerService.phone }}</h2>
                    </a>
                </v-row>

                <!-- WHATSAPP -->
                <v-row class="locationDetail noSpace" align="start" justify="start">
                    <a :href="`https://wa.me/52${customerService.whatsapp}`" target="_blank">
                        <span class="centuryGothic locationDataText">WhatsApp: {{ customerService.whatsapp }} <br></span>
                    </a>
                </v-row>

                <!-- OFFICE PHONE -->
                <v-row class="locationDetail noSpace" align="start" justify="start">
                    <a :href="`tel:${customerService.officeNumber}`" target="_blank">
                        <span class="centuryGothic locationDataText">
                            <a :href="`tel:${customerService.officeNumber}`" >
                                <span class="centuryGothic locationDataText">Tel. Oficinas: {{ customerService.officeNumber }} <br></span>
                            </a>
                        </span>
                    </a>
                </v-row>

                <!-- LOCATION CONTACT EMAIL -->
                <v-row class="locationDetail noSpace" align="start" justify="start">
                    <v-col cols="12" md="6" class="pa-0">
                        <a :href="`mailto:${customerService.email}`" target="_blank">
                            <p class="centuryGothic locationDataText mt-2">
                                {{ customerService.email }}
                            </p>
                        </a>
                    </v-col>
                </v-row>

                <!-- OFFICE NAME NAME -->
                <v-row class="locationDetail locationName noSpace mt-6" align="start" justify="start">
                    <p class="centuryGothicBold screenTitle">{{ corporate.title }}</p>
                </v-row>

                <!-- LOCATION ADDRESS -->
                <v-row class="locationDetail noSpace mt-n4" align="start" justify="start">
                    <a :href="`${mapsBaseUrl}${location.markerOptions.position.lat},${location.markerOptions.position.lng}`" target="_blank">
                        <p class="centuryGothic locationDataText">
                            Paseo Ópera #2 Corporativo Angelópolis, <br>Lomas de Angelópolis, Tlaxcalancingo, 72830.<br> Puebla, México.
                        </p>
                    </a>
                </v-row>
            </v-col>
        </v-row>


        <!-- CONTACT FORM -->
        <v-row class="pa-0 mb-n16" justify="center" align="center">
            <v-container fluid style="height: auto; background-color: #EBEAEA;">
                <!-- CTA TITLE -->
                <v-row class="pt-10" align="center" justify="center">
                    <p class="centuryGothicBold screenTitle">ENVÍANOS UN MENSAJE</p>
                </v-row>

                <v-row class="pa-0" align="center" justify="center">
                    <!-- LEAD INFO TEXT FIELDS COLUMN -->
                    <v-col cols="12" md="6" class="formColumn noSpace pl-16">
                        <v-container fill-height fluid>
                            <v-col class="pa-0">
                                <!-- NAME TEXT FIELD -->
                                <v-text-field
                                    background-color="white"
                                    v-model="name"
                                    label="Nombre"
                                    class="mb-n3"
                                    outlined
                                    filled
                                    dense
                                    @keyup.enter.exact="sendContactFormRequest"
                                ></v-text-field>

                                <!-- MAIL TEXT FIELD -->
                                <v-text-field
                                    background-color="white"
                                    v-model="mail"
                                    label="Mail"
                                    class="mb-n3"
                                    outlined
                                    filled
                                    dense
                                    @keyup.enter.exact="sendContactFormRequest"
                                ></v-text-field>

                                <!-- PHONE TEXT FIELD -->
                                <v-text-field
                                    background-color="white"
                                    v-model="phone"
                                    label="Teléfono"
                                    outlined
                                    filled
                                    dense
                                    @keyup.enter.exact="sendContactFormRequest"
                                ></v-text-field>
                            </v-col>
                        </v-container>
                    </v-col>

                    <!-- MESSAGE TEXTAREA COLUMN -->
                    <v-col cols="12" md="6" class="formColumn noSpace pr-16">
                        <v-container fill-height fluid>
                            <v-col class="noSpace" align-self="start" align="start">
                                <!-- MESSAGE TEXTAREA -->
                                <v-textarea
                                    id="messageTextarea"
                                    background-color="white"
                                    style="height: 100%;"
                                    v-model="message"
                                    label="Mensaje"
                                    outlined
                                    filled
                                    no-resize
                                    @keyup.enter.exact="sendContactFormRequest"
                                ></v-textarea>
                            </v-col>
                        </v-container>
                    </v-col>
                </v-row>

                <v-row class="pa-0 pb-10" align="center" justify="center">
                    <v-btn
                        class="centuryGothicBold"
                        color="secondary"
                        dark
                        elevation="6"
                        style="padding: 20px 40px;"
                        @click="sendContactFormRequest"
                    >Enviar</v-btn>
                </v-row>
            </v-container>
        </v-row>

        <!-- INVALID FORM DIALOG -->
        <v-dialog
            v-model="invalidForm"
            max-width="500"
            overlay-opacity="0.8"
            persistent
        >
            <v-card class="pa-16" color="white">
                <v-row align="center" justify="center">
                    <v-col align-self="center">
                        <h1 class="primary--text mt-n8" style="text-align: center;">{{ dialogTitle.toUpperCase() }}</h1>

                        <p class="mt-4" style="text-align: center;">{{ dialogMessage }}</p>

                        <v-row align="center" class="mt-8" justify="center">
                            <button
                                class="dialogButton"
                                @click="invalidForm = false"
                            >Cerrar
                            </button>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {gmapsMap, gmapsMarker} from "x5-gmaps";
import axios from "axios";

export default {
    name: "Contact",
    components: {
        gmapsMap,
        gmapsMarker,
    },
    data() {
        return {
            name: '',
            phone: '',
            mail: '',
            message: '',
            mapsBaseUrl: 'https://www.google.com/maps/search/?api=1&query=',
            location: {
                mapOptions: {
                    center: {
                        lat: 18.9927152,
                        lng: -98.2770322,
                    },
                    zoom: 15,
                    scrollwheel: true,
                    backgroundColor: "#EE403D",
                    fullscreenControl: false,
                    keyboardShortcuts: false,
                    streetViewControl: false,
                },
                markerOptions: {
                    position: {
                        lat: 18.9927152,
                        lng: -98.2770322,
                    },
                    draggable: false,
                }
            },
            corporate: {
                title: 'Oficinas Corporativas',
                address: 'Paseo Ópera #2 Corporativo Angelópolis, Lomas de Angelópolis, Tlaxcalancingo, 72830. Puebla, México.'
            },
            customerService: {
                title: 'Centro de Atención a Clientes',
                phone: '800 122 5437',
                whatsapp: '2224218785',
                officeNumber: '2223091400',
                email: 'atencion@mezclabrava.com'
            },
            dialogTitle: '',
            dialogMessage: '',
            invalidForm: false,
        }
    },
    methods: {
        async sendContactFormRequest() {
            let filledForm = this.validateEmptyFields();
            let validEmail = this.testEmailFormat();
            let validPhone = this.testPhoneFormat();

            if(filledForm === true) {
                if(validEmail === true) {
                    if(validPhone === true) {
                        let formObject = {
                            'name': this.name,
                            'mail': this.mail,
                            'phone': this.phone,
                            'message': this.message
                        }

                        // let response = await axios.post('http://localhost:5001/mezcla-brava/us-central1/sendLeadThroughEmail', formObject);
                        let response = await axios.post('https://us-central1-mezcla-brava.cloudfunctions.net/sendLeadThroughEmail', formObject);
                        // console.log(response);

                        if(response.status === 200) {
                            // Record Leads into Marketing Platforms
                            window.gtag('event', 'lead', {'send_to': 'AW-10845389810/xxxZCKyZwLUDEPKHvrMo'});
                            window.fbq('track', 'Lead');

                            this.dialogTitle = "Gracias";
                            this.dialogMessage = "Nos pondremos en contacto a la brevedad.";
                            this.invalidForm = true;

                            this.clearFormFields();

                        } else {
                            this.dialogTitle = "ERROR";
                            this.dialogMessage = "Ha ocurrido un error al enviar tu información, intentalo más tarde.";
                            this.invalidForm = true;
                        }

                    } else {
                        this.dialogTitle = "ERROR";
                        this.dialogMessage = "Teléfono inválido, debe tener únicamente 10 dígitos.";
                        this.invalidForm = true;
                    }
                } else {
                    this.dialogTitle = "ERROR";
                    this.dialogMessage = "Email inválido";
                    this.invalidForm = true;
                }
            } else {
                this.dialogTitle = "ERROR";
                this.dialogMessage = "Debes llenar todos los campos del formulario";
                this.invalidForm = true;
            }
        },

        clearFormFields() {
            this.name = "";
            this.mail = "";
            this.phone = "";
            this.message = "";
        },

        testPhoneFormat() {
            const pattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
            let result = false;

            if(this.phone.match(pattern) !== null) {
                result = true;
            }

            return result;
        },

        testEmailFormat() {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let result = false;

            if(this.mail.match(pattern) !== null) {
                result = true;
            }

            return result;
        },

        validateEmptyFields() {
          return this.name !== '' && this.mail !== '' && this.phone !== '' && this.message !== '';
        },
    },
    metaInfo() {
        return {
            title: 'Mezcla Brava | Contacto',
            meta: [
                { vmid: 'description', name: 'description', content: 'Mezcla Brava es parte de la gran familia de GRUPO CALIDRA; la empresa con mayor tradición en la industria de la construcción y orgullosamente mexicanos.' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'twitter:image', name: 'twitter:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:url', name: 'og:url', content: 'https://www.mezclabrava.com/' },
                { vmid: 'twitter:url', name: 'twitter:url', content: 'https://www.mezclabrava.com/' },
            ]
        }
    },
}
</script>

<style scoped>
.dialogButton {
    color: #fff;
    background-color: #e6413d;
    padding: 10px 50px;
    font-weight: bold;
    border-radius: 5px;
}

textarea {
    background-color: #FFFFFF !important;
}

.v-application a {
    color: #3D3533 !important;
    text-decoration: none;
}

.locationDataText:hover {
    color: #EE403D;
    text-decoration: underline;
}

.locationMap {
    height: 50vh;
    padding: 0 50px !important;
}

@media (max-width: 960px) {
    .locationMap {
        height: 30vh;
        padding: 0 10px !important;
    }

    .locationDetail {
        justify-self: center !important;
        justify-content: center !important;
        justify-items: center !important;
        padding: 0 10px !important;
        text-align: center !important;
    }

    .locationName {
        margin-top: 20px !important;
    }

    .formColumn {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    #messageTextarea {
        padding-top: -100px !important;
        margin-top: -100px !important;
    }
}
</style>